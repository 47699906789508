import {
  AutomationTargetType,
  AutomationBaseDefinition,
} from './automation-base-types';
import {
  AutomationConditionalFilterFields,
  AutomationConditionalFilterNames,
  AutomationFilterValueTypes,
} from './automation-filters';
import { EntityType } from '../../constants';
import {
  AutomationOperator,
  AutomationOperatorComboBoxOptions,
  AutomationOperatorType,
} from './automation-operators';
import {
  AutomationLogicalOperator,
  AutomationNodeType,
} from './automation-types';

export interface AutomationCondition {
  target: string;
  operator: string;
  values: string[];
  filter?: string;
  filterValues?: string[];
  valueType?: AutomationFilterValueTypes;
  preFilter?: string;
  stringValue?: string;
  stringArrayValues?: string[];
  numberValue?: number;
  floatValue?: number;
}

export interface AutomationCriteria {
  logicalOperator: AutomationLogicalOperator;
  conditions: Array<AutomationCondition>;
}

export interface AutomationConditionalCase {
  id: string;
  name?: string;
  criteria: AutomationCriteria;
  targetOutputId: string;
}

export interface AutomationDefaultCase {
  id: string;
  targetOutputId: string;
  name?: string;
}

export interface AutomationConditionalDefinition
  extends AutomationBaseDefinition {
  group: string;
  platformOnly?: boolean;
  applicableNodes: AutomationNodeType[];
  valueType?: AutomationConditionalValueTypes;
  operators?: AutomationOperator[];
  lookup?: AutomationConditionalFilterNames;
  dynamicLookup?: AutomationConditionalFilterNames;
  preFilter?: boolean;
  preFilterField?: AutomationConditionalFilterFields;
}

export enum AutomationConditionalGroups {
  Form = 'Form',
  Email = 'Email',
  Platform = 'Platform',
  Customer = 'Customer',
  Product = 'Product',
  CustomerTag = 'Customer Tag',
  CustomerList = 'Customer List',
  Campaign = 'Campaign',
  Course = 'Course',
  Challenge = 'Challenge',
  Page = 'Page',
  Post = 'Post',
  Membership = 'Membership',
}

export enum AutomationConditionalValueTypes {
  Percent = 'percent',
  Boolean = 'boolean',
  NotApplicable = 'na',
  StringExistance = 'stringexistance',
  String = 'string',
  Number = 'number',
  Date = 'date',
  Currency = 'currency',
}

// not implemented or not applicable
type ExcludeAutomationTargetTypeConditional =
  | AutomationTargetType.CourseSectionCompleted
  | AutomationTargetType.CourseSectionViewed
  | AutomationTargetType.SubscriptionRenewal
  | AutomationTargetType.FormSubmitted
  | AutomationTargetType.SubscriberCreated
  | AutomationTargetType.MemberCreated
  | AutomationTargetType.AffiliateCreated
  | AutomationTargetType.ChallengeEventAdded
  | AutomationTargetType.TagAdded
  | AutomationTargetType.TagRemoved
  | AutomationTargetType.ListAdded
  | AutomationTargetType.ListRemoved
  | AutomationTargetType.DoNotContactUnmarked;

export const AutomationConditionalDefinitions: Record<
  Exclude<AutomationTargetType, ExcludeAutomationTargetTypeConditional>,
  AutomationConditionalDefinition
> = {
  [AutomationTargetType.IsMember]: {
    name: 'Is Member',
    target: AutomationTargetType.IsMember,
    entity: EntityType.Customer,
    group: AutomationConditionalGroups.Customer,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.Yes],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.No],
    ],
    applicableNodes: [AutomationNodeType.Conditional],
    preFilter: false,
  },
  [AutomationTargetType.IsSubscriber]: {
    name: 'Is Subscriber',
    target: AutomationTargetType.IsSubscriber,
    entity: EntityType.Customer,
    group: AutomationConditionalGroups.Customer,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.Yes],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.No],
    ],
    applicableNodes: [AutomationNodeType.Conditional],
    preFilter: false,
  },
  [AutomationTargetType.Unsubscribed]: {
    name: 'Unsubscribed',
    target: AutomationTargetType.Unsubscribed,
    entity: EntityType.CustomerList,
    group: AutomationConditionalGroups.Customer,
    lookup: AutomationConditionalFilterNames.List,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.FromAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.FromAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.FromNone],
    ],
    applicableNodes: [AutomationNodeType.Conditional],
    preFilter: false,
  },
  [AutomationTargetType.DoNotContactMarked]: {
    name: 'Do Not Contact Marked',
    target: AutomationTargetType.DoNotContactMarked,
    entity: EntityType.Customer,
    group: AutomationConditionalGroups.Customer,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.Yes],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.No],
    ],
    applicableNodes: [AutomationNodeType.Conditional],
    preFilter: false,
  },
  [AutomationTargetType.CourseAccess]: {
    name: 'Course Access',
    target: AutomationTargetType.CourseAccess,
    entity: EntityType.Course,
    group: AutomationConditionalGroups.Course,
    lookup: AutomationConditionalFilterNames.Course,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.DoesNotHave],
    ],
    applicableNodes: [AutomationNodeType.Conditional],
    preFilter: false,
  },
  [AutomationTargetType.ChallengeAccess]: {
    name: 'Challenge Access',
    target: AutomationTargetType.ChallengeAccess,
    entity: EntityType.Challenge,
    group: AutomationConditionalGroups.Challenge,
    lookup: AutomationConditionalFilterNames.Challenge,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.DoesNotHave],
    ],
    applicableNodes: [AutomationNodeType.Conditional],
    preFilter: false,
  },
  [AutomationTargetType.MembershipAccess]: {
    name: 'Membership Access',
    target: AutomationTargetType.MembershipAccess,
    entity: EntityType.Membership,
    group: AutomationConditionalGroups.Membership,
    lookup: AutomationConditionalFilterNames.Membership,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.DoesNotHave],
    ],
    applicableNodes: [AutomationNodeType.Conditional],
    preFilter: false,
  },
  [AutomationTargetType.PageViewed]: {
    name: 'Page Viewed',
    target: AutomationTargetType.PageViewed,
    entity: EntityType.Page,
    group: AutomationConditionalGroups.Page,
    lookup: AutomationConditionalFilterNames.Page,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ViewedAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ViewedAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ViewedNone],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.PostViewed]: {
    name: 'Post Viewed',
    target: AutomationTargetType.PostViewed,
    entity: EntityType.Post,
    group: AutomationConditionalGroups.Post,
    lookup: AutomationConditionalFilterNames.Post,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ViewedAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ViewedAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ViewedNone],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.FormFieldChecked]: {
    name: 'Form Field Checked',
    target: AutomationTargetType.FormFieldChecked,
    entity: EntityType.Form,
    group: AutomationConditionalGroups.Form,
    lookup: AutomationConditionalFilterNames.Form,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.AllChecked],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.NoneChecked],
    ],
    dynamicLookup: AutomationConditionalFilterNames.FormCheckBox,
    applicableNodes: [AutomationNodeType.Conditional],
    preFilter: true,
    preFilterField: AutomationConditionalFilterFields.Form,
  },
  [AutomationTargetType.CourseCompletedPercentage]: {
    name: 'Course Completed Percentage',
    target: AutomationTargetType.CourseCompletedPercentage,
    entity: EntityType.Course,
    group: AutomationConditionalGroups.Course,
    lookup: AutomationConditionalFilterNames.Course,
    valueType: AutomationConditionalValueTypes.Percent,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.GT],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.GE],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.LT],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.LE],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: true,
    preFilterField: AutomationConditionalFilterFields.CourseId,
  },
  [AutomationTargetType.CourseViewedPercentage]: {
    name: 'Course Viewed Percentage',
    target: AutomationTargetType.CourseViewedPercentage,
    entity: EntityType.Course,
    group: AutomationConditionalGroups.Course,
    lookup: AutomationConditionalFilterNames.Course,
    valueType: AutomationConditionalValueTypes.Percent,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.GEInAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.GEIn],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.GEInNone],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.LEInAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.LEIn],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.LEInNone],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: true,
    preFilterField: AutomationConditionalFilterFields.CourseId,
  },
  [AutomationTargetType.ChallengeCompletedPercentage]: {
    name: 'Challenge Completed Percentage',
    target: AutomationTargetType.ChallengeCompletedPercentage,
    entity: EntityType.Challenge,
    group: AutomationConditionalGroups.Challenge,
    lookup: AutomationConditionalFilterNames.Challenge,
    valueType: AutomationConditionalValueTypes.Percent,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.GT],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.GE],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.LT],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.LE],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: true,
    preFilterField: AutomationConditionalFilterFields.ChallengeId,
  },
  [AutomationTargetType.EmailConfirmed]: {
    name: 'Email Confirmed',
    target: AutomationTargetType.EmailConfirmed,
    entity: EntityType.Email,
    group: AutomationConditionalGroups.Email,
    valueType: AutomationConditionalValueTypes.Boolean,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.Yes],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.No],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.EmailMailOpened]: {
    name: 'Email Opened',
    target: AutomationTargetType.EmailMailOpened,
    entity: EntityType.Email,
    group: AutomationConditionalGroups.Email,
    lookup: AutomationConditionalFilterNames.Email,
    valueType: AutomationConditionalValueTypes.NotApplicable,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.OpenedAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.OpenedAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.OpenedNone],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.EmailMailClicked]: {
    name: 'Email Clicked',
    target: AutomationTargetType.EmailMailClicked,
    entity: EntityType.Email,
    group: AutomationConditionalGroups.Email,
    lookup: AutomationConditionalFilterNames.Email,
    valueType: AutomationConditionalValueTypes.NotApplicable,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ClickedAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ClickedAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ClickedNone],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.CampaignMailOpened]: {
    name: 'Campaign Opened',
    target: AutomationTargetType.CampaignMailOpened,
    entity: EntityType.Campaign,
    group: AutomationConditionalGroups.Campaign,
    lookup: AutomationConditionalFilterNames.Campaign,
    valueType: AutomationConditionalValueTypes.NotApplicable,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.OpenedAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.OpenedAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.OpenedNone],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.CampaignMailClicked]: {
    name: 'Campaign Clicked',
    target: AutomationTargetType.CampaignMailClicked,
    entity: EntityType.Campaign,
    group: AutomationConditionalGroups.Campaign,
    lookup: AutomationConditionalFilterNames.Campaign,
    valueType: AutomationConditionalValueTypes.NotApplicable,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ClickedAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ClickedAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.ClickedNone],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.ProductPurchased]: {
    name: 'Product Purchased',
    target: AutomationTargetType.ProductPurchased,
    entity: EntityType.Product,
    group: AutomationConditionalGroups.Product,
    lookup: AutomationConditionalFilterNames.Product,
    valueType: AutomationConditionalValueTypes.NotApplicable,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.OwnsAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.OwnsAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.DoesNotOwn],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.Tag]: {
    name: 'Tag',
    target: AutomationTargetType.Tag,
    entity: EntityType.Customer,
    group: AutomationConditionalGroups.CustomerTag,
    lookup: AutomationConditionalFilterNames.CustomerTag,
    valueType: AutomationConditionalValueTypes.NotApplicable,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.DoesNotHave],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.List]: {
    name: 'List',
    target: AutomationTargetType.List,
    entity: EntityType.Customer,
    group: AutomationConditionalGroups.CustomerList,
    lookup: AutomationConditionalFilterNames.List,
    valueType: AutomationConditionalValueTypes.NotApplicable,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAny],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.HasAll],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.DoesNotHave],
    ],
    applicableNodes: [AutomationNodeType.Conditional, AutomationNodeType.Wait],
    preFilter: false,
  },
  [AutomationTargetType.PlatformAssigned]: {
    name: 'Platform Assigned',
    target: AutomationTargetType.PlatformAssigned,
    entity: '',
    group: AutomationConditionalGroups.Platform,
    applicableNodes: [AutomationNodeType.Conditional],
    platformOnly: true,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.Exists],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.DoesNotExist],
    ],
    valueType: AutomationConditionalValueTypes.StringExistance,
    preFilter: false,
  },
  [AutomationTargetType.IsTenantTrial]: {
    name: 'Is Tenant Trial',
    target: AutomationTargetType.IsTenantTrial,
    entity: '',
    group: AutomationConditionalGroups.Platform,
    applicableNodes: [AutomationNodeType.Conditional],
    platformOnly: true,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.Yes],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.No],
    ],
    valueType: AutomationConditionalValueTypes.Boolean,
    preFilter: false,
  },
  [AutomationTargetType.WasCustomerTrial]: {
    name: 'Was Customer Trial',
    target: AutomationTargetType.WasCustomerTrial,
    entity: '',
    group: AutomationConditionalGroups.Customer,
    applicableNodes: [AutomationNodeType.Conditional],
    platformOnly: true,
    operators: [
      AutomationOperatorComboBoxOptions[AutomationOperatorType.Yes],
      AutomationOperatorComboBoxOptions[AutomationOperatorType.No],
    ],
    valueType: AutomationConditionalValueTypes.Boolean,
    preFilter: false,
  },
};
