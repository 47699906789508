<div *ngIf="(stripeActive$ | async) === false"
  class="tw-bg-info-400 tw-px-6 tw-py-2.5 sm:tw-px-3.5 sm:before:tw-flex-1">
  <p class="tw-text-sm tw-leading-6 tw-w-full tw-text-center tw-text-white">
    <a [routerLink]="['/integrations/stripe/connect']">
      <strong class="tw-font-semibold">Welcome aboard! Please enable the stripe integration to allow signups and
        purchases.</strong><span aria-hidden="true"> &rarr;</span>
    </a>
  </p>
</div>
<div class="tw-flex tw-h-full">
  <div *ngIf='isOffCanvasMenu' class="tw-relative tw-z-40 lg:tw-hidden" role="dialog" aria-modal="true">
    <div @AnimationTrigger0 *ngIf='isOffCanvasMenu' class="tw-fixed tw-inset-0 tw-bg-gray-600 tw-bg-opacity-75">
    </div>
    <div class="tw-fixed tw-inset-0 tw-z-40 tw-flex">
      <div @AnimationTrigger1 *ngIf='isOffCanvasMenu'
        class="tw-relative tw-flex tw-w-full tw-max-w-xs tw-flex-1 tw-flex-col tw-bg-white focus:tw-outline-none">
        <div @AnimationTrigger2 *ngIf='isOffCanvasMenu' class="tw-absolute tw-top-0 tw-right-0 -tw-mr-12 tw-pt-2">
          <button type="button" (click)="toggleOffCanvasMenu()"
            class="tw-ml-1 tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-inset focus:tw-ring-white">
            <span class="tw-sr-only">Close sidebar</span>
            <svg class="tw-h-6 tw-w-6 tw-text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
              stroke-width="1.5" stroke="currentColor" aria-hidden="true">
              <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        <div class="tw-h-0 tw-flex-1 tw-overflow-y-auto tw-pt-5 tw-pb-4">
          <div class="tw-flex tw-flex-shrink-0 tw-items-center tw-px-4">
            <img class="tw-h-8 tw-w-auto" [src]="condensedlogoUrl" alt="Workflow">
          </div>
          <nav class="tw-mt-5 tw-flex-1" aria-label="Sidebar">
            <div class="">
              <div class="sidebar-section">Overview</div>
              <a [routerLink]="['/analytics/dashboard']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Dashboard
              </a>
              <div class="sidebar-section">Analytics</div>
              <a [routerLink]="['/analytics/sessions']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Sessions
              </a>
              <a [routerLink]="['/analytics/products']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Products
              </a>
              <a [routerLink]="['/analytics/acquisition']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Acquisition
              </a>
              <a [routerLink]="['/analytics/revenue']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Revenue
              </a>
              <a [routerLink]="['/analytics/pages']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Pages
              </a>
              <div class="sidebar-section">Create</div>
              <a [routerLink]="['/library/assets']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Assets
              </a>
              <a [routerLink]="['/library/courses']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Courses
              </a>
              <a [routerLink]="['/library/challenges']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Challenges
              </a>
              <a [routerLink]="['/library/pages']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Pages
              </a>
              <a [routerLink]="['/library/posts']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Posts
              </a>
              <a [routerLink]="['/library/flows']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Flows
              </a>
              @if (isPlatformEnabled){
              <a [routerLink]="['/library/help']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Help Content
              </a>
              }
              <div class="sidebar-section">Marketing</div>
              <a [routerLink]="['/library/emails']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Emails
              </a>
              <a [routerLink]="['/library/forms']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Forms
              </a>
              <a [routerLink]="['/library/workflows']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Workflows
              </a>
              <a [routerLink]="['/library/automations']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Automations [Beta]
              </a>
              <a [routerLink]="['/people/campaigns']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Campaigns
              </a>
              <a [routerLink]="['/people/lists']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Lists
              </a>
              <a [routerLink]="['/people/tags']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Tags
              </a>
              <div class="sidebar-section">Community</div>
              <a [routerLink]="['/community/spaces']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Spaces
              </a>
              <div class="sidebar-section">Moderation</div>
              <a [routerLink]="['/community/comments']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Comments
              </a>
              <a [routerLink]="['/community/threads']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Threads
              </a>
              <a [routerLink]="['/config/community']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Configuration
              </a>
              <div class="sidebar-section">Scheduling</div>
              <a [routerLink]="['/scheduling/calendar']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Calendar
              </a>
              <a [routerLink]="['/scheduling/availability']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Availability
              </a>
              <a [routerLink]="['/config/scheduling']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Settings
              </a>

              <div class="sidebar-section">Sell</div>
              <a [routerLink]="['/catalog/products']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <div class="tw-flex tw-w-full tw-items-center">
                  <div>Products</div>
                  <div class="tw-flex-grow"></div>
                  <div *ngIf="(stripeActive$ | async) === false" sidkikTooltip="Enable stripe to create products"
                    class="tw-w-fit">
                    <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                  </div>
                </div>
              </a>
              <a [routerLink]="['/catalog/coupons']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <div class="tw-flex tw-w-full tw-items-center">
                  <div>Coupons</div>
                  <div class="tw-flex-grow"></div>
                  <div *ngIf="(stripeActive$ | async) === false" sidkikTooltip="Enable stripe to create coupons"
                    class="tw-w-fit">
                    <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                  </div>
                </div>
              </a>
              <div class="sidebar-section">People</div>
              <a [routerLink]="['/people/customers']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Customers
              </a>
              <a [routerLink]="['/people/affiliates']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Affiliates
              </a>
              <a [routerLink]="['/people/users']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Users
              </a>
              <div class="sidebar-section">Appearance</div>
              <a [routerLink]="['/config/theme']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Theme
              </a>
              <a [routerLink]="['/config/networking']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Domains
              </a>
              <a [routerLink]="['/config/navigation']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Navigation
              </a>
              <div class="sidebar-section">Integrations</div>
              <a [routerLink]="['/integrations/stripe']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                <div class="tw-flex tw-w-full tw-items-center">
                  <div>Stripe</div>
                  <div class="tw-flex-grow"></div>
                  <div *ngIf="(stripeActive$ | async) === false"
                    sidkikTooltip="Stripe integration must be active to allow signups and purchases" class="tw-w-fit">
                    <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                  </div>
                </div>
              </a>
              <a [routerLink]="['/integrations/quickbooks/config']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Quickbooks
              </a>
              <a [routerLink]="['/integrations/vimeo/config']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Vimeo
              </a>
              <a [routerLink]="['/integrations/mailer/config']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Mailer
              </a>
              <a [routerLink]="['/integrations/slack/config']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Slack
              </a>
              <a [routerLink]="['/integrations/zoom/config']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Zoom
              </a>
              <div class="sidebar-section">Extras</div>
              <a [routerLink]="['/config/accounting']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Accounting
              </a>
              <a [routerLink]="['/config/addon']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Addons
              </a>
              <a [routerLink]="['/config/debug']" routerLinkActive="active" class="sidebar-item"
                (click)="toggleOffCanvasMenu()">
                Debug
              </a>
            </div>
          </nav>
        </div>
        <sidkik-user-info></sidkik-user-info>
      </div>
      <div class=" tw-w-14 tw-flex-shrink-0" aria-hidden="true">
      </div>
    </div>
  </div>
  <!-- Sidebar -->
  <div class="tw-hidden tw-w-[16rem] lg:tw-flex lg:tw-flex-col lg:tw-flex-shrink-0 tw-bg-white">
    <div class="tw-flex tw-flex-none tw-items-center tw-justify-center tw-h-[88px] tw-px-0">
      <img class="tw-w-24" [src]="logoUrl" alt="Workflow">
    </div>
    <div class="desktop-sidebar">
      <div class="sidebar-main" [class.minimized]="activeSection !== undefined">
        <div class="sidebar-main-content">
          <div class="tw-flex tw-min-h-0 tw-flex-1 tw-flex-col ">
            <div class="tw-flex tw-flex-1 tw-flex-col tw-pt-0 tw-pb-4">
              <nav class="tw-mt-0 tw-flex tw-flex-col tw-flex-1" aria-label="Sidebar">
                <div class="">
                  <div class="sidebar-section">Overview</div>
                  <a [routerLink]="['/analytics/dashboard']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Dashboard
                    </div>
                  </a>
                  <a [routerLink]="['/analytics/sessions']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(sections.Analytics)">
                    <div class="sidebar-item-main-link">
                      Reports
                    </div>
                  </a>
                  <div class="sidebar-section">Create</div>
                  <a [routerLink]="['/library/assets']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Assets
                    </div>
                  </a>
                  <a [routerLink]="['/library/courses']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Courses
                    </div>
                  </a>
                  <a [routerLink]="['/library/challenges']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Challenges
                    </div>
                  </a>
                  <a [routerLink]="['/library/pages']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Pages
                    </div>
                  </a>
                  <a [routerLink]="['/library/posts']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Posts
                    </div>
                  </a>
                  <a [routerLink]="['/library/flows']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Flows
                    </div>
                  </a>
                  @if (isPlatformEnabled){
                  <a [routerLink]="['/library/help']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Help Content
                    </div>
                  </a>
                  }
                  <div class="sidebar-section">Interact</div>
                  <a [routerLink]="['/people/campaigns']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(sections.Marketing)">
                    <div class="sidebar-item-main-link more">
                      Marketing
                    </div>
                  </a>
                  <a [routerLink]="['/community/spaces']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(sections.Community)">
                    <div class="sidebar-item-main-link more">
                      Community
                    </div>
                  </a>
                  <a [routerLink]="['/scheduling']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(sections.Availability)">
                    <div class="sidebar-item-main-link more">
                      Scheduling
                    </div>
                  </a>
                  <div class="sidebar-section">Sell</div>
                  @if (stripeActive$ | async){
                  <a [routerLink]="['/catalog/products']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Products
                    </div>
                  </a>
                  } @else {
                  <a class="sidebar-item-main">
                    <div class="tw-flex tw-w-full tw-items-center">
                      <div>Products</div>
                      <div class="tw-flex-grow"></div>
                      <div sidkikTooltip="Enable stripe to create products" class="tw-w-fit">
                        <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                      </div>
                    </div>
                  </a>
                  }
                  @if (stripeActive$ | async){
                  <a [routerLink]="['/catalog/coupons']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Coupons
                    </div>
                  </a>
                  } @else {
                  <a class="sidebar-item-main">
                    <!-- <sidkik-icon svgIcon="coupon"></sidkik-icon> -->
                    <div class="tw-flex tw-w-full tw-items-center">
                      <div>Coupons</div>
                      <div class="tw-flex-grow"></div>
                      <div sidkikTooltip="Enable stripe to create coupons" class="tw-w-fit">
                        <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                      </div>
                    </div>
                  </a>
                  }

                  <div class="sidebar-section">People</div>
                  <a [routerLink]="['/people/customers']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Customers
                    </div>
                  </a>
                  <a [routerLink]="['/people/affiliates']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Affiliates
                    </div>
                  </a>
                  <a [routerLink]="['/people/users']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(undefined)">
                    <div class="sidebar-item-main-link">
                      Users
                    </div>
                  </a>
                  <div class="sidebar-section">Settings</div>
                  <a [routerLink]="['/config/theme']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(sections.Appearance)">
                    <div class="sidebar-item-main-link more">
                      Appearance
                    </div>
                  </a>
                  <a [routerLink]="['/integrations/stripe']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(sections.Integrations)">
                    <div class="sidebar-item-main-link more">
                      Integrations
                    </div>
                  </a>
                  <a [routerLink]="['/config/addon']" routerLinkActive="active" class="sidebar-item-main"
                    (click)="toggleSection(sections.Extras)">
                    <div class="sidebar-item-main-link more">
                      Extras
                    </div>
                  </a>
                </div>
                <div class="sidebar-section">Help</div>

                <div>
                  <a [routerLink]="['/help']" class="sidebar-item-main" routerLinkActive="active">
                    <!-- <a [routerLink]="['/help']" class="sidebar-item-main" routerLinkActive="active"
                    (click)="toggleSection(sections.Help)"> -->
                    <div class="sidebar-item-main-link ">
                      Guides
                    </div>
                  </a>
                </div>
              </nav>
            </div>

          </div>
        </div>

      </div>
      <div class="sidebar-subsection" [class.minimized]="activeSection === undefined">
        <div class="sidebar-subsection-content">
          <div class="tw-flex tw-min-h-0 tw-flex-1 tw-flex-col tw-h-full tw-border-l tw-border-gray-500 ">
            <div class="tw-flex tw-flex-1 tw-flex-col tw-pt-0 tw-pb-4">
              <nav class="tw-mt-0 tw-flex tw-flex-col tw-flex-1" aria-label="Sidebar">
                <div (click)="toggleSection(undefined)"
                  class="tw-w-full tw-flex tw-items-center tw-uppercase tw-px-4 tw-mb-4 tw-cursor-pointer tw-text-xs tw-text-gray-700 tw-gap-x-2 sm:tw-w-auto  hover:tw-bg-gray-100 ">
                  <svg class="tw-w-5 tw-h-5 rtl:tw-rotate-180" xmlns="http://www.w3.org/2000/svg" fill="none"
                    viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 15.75L3 12m0 0l3.75-3.75M3 12h18" />
                  </svg>
                  <span>Go back</span>
                </div>
                <div class="tw-h-full" [class.tw-hidden]="activeSection !== undefined"
                  [class.tw-block]="activeSection === undefined">
                  &nbsp;
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Analytics"
                  [class.tw-block]="activeSection === sections.Analytics">
                  <div class="sidebar-section">Analytics</div>
                  <a [routerLink]="['/analytics/sessions']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Sessions
                    </div>
                  </a>
                  <a [routerLink]="['/analytics/products']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Products
                    </div>
                  </a>
                  <a [routerLink]="['/analytics/acquisition']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Acquisition
                    </div>
                  </a>
                  <a [routerLink]="['/analytics/revenue']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Revenue
                    </div>
                  </a>
                  <a [routerLink]="['/analytics/pages']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Pages
                    </div>
                  </a>
                </div>

                <div [class.tw-hidden]="activeSection !== sections.Marketing"
                  [class.tw-block]="activeSection === sections.Marketing">
                  <div class="sidebar-section">Marketing</div>
                  <a [routerLink]="['/library/emails']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Emails
                    </div>
                  </a>
                  <a [routerLink]="['/library/forms']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Forms
                    </div>
                  </a>
                  <a [routerLink]="['/library/workflows']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Workflows
                    </div>
                  </a>
                  <a [routerLink]="['/library/automations']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Automations [Beta]
                    </div>
                  </a>
                  <a [routerLink]="['/people/campaigns']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Campaigns
                    </div>
                  </a>
                  <a [routerLink]="['/people/lists']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Lists
                    </div>
                  </a>
                  <a [routerLink]="['/people/tags']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Tags
                    </div>
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Community"
                  [class.tw-block]="activeSection === sections.Community">
                  <div class="sidebar-section">Community</div>
                  <a [routerLink]="['/community/spaces']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Spaces
                    </div>
                  </a>
                  <div class="sidebar-section">Moderation</div>
                  <a [routerLink]="['/community/comments']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Comments
                    </div>
                  </a>
                  <a [routerLink]="['/community/threads']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Threads
                    </div>
                  </a>
                  <a [routerLink]="['/config/community']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Configuration
                    </div>
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Availability"
                  [class.tw-block]="activeSection === sections.Availability">
                  <div class="sidebar-section">Scheduling</div>
                  <a [routerLink]="['/scheduling/calendar']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Calendar
                    </div>
                  </a>
                  <a [routerLink]="['/scheduling/availability']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Availability
                    </div>
                  </a>
                  <a [routerLink]="['/config/scheduling']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Settings
                    </div>
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Appearance"
                  [class.tw-block]="activeSection === sections.Settings">
                  <div class="sidebar-section">Appearance</div>
                  <a [routerLink]="['/config/theme']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Theme
                    </div>
                  </a>
                  <a [routerLink]="['/config/networking']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Domains
                    </div>
                  </a>
                  <a [routerLink]="['/config/navigation']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Navigation
                    </div>
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Integrations"
                  [class.tw-block]="activeSection === sections.Settings">
                  <div class="sidebar-section">Integrations</div>
                  <a [routerLink]="['/integrations/stripe']" routerLinkActive="active" class="sidebar-item-main">
                    <!-- <sidkik-icon svgIcon="integrations"></sidkik-icon> -->
                    <div class="tw-flex tw-w-full tw-items-center">
                      <div class="sidebar-item-main-link">Stripe</div>
                      <div class="tw-flex-grow"></div>
                      <div *ngIf="(stripeActive$ | async) === false"
                        sidkikTooltip="Stripe integration must be active to allow signups and purchases"
                        class="tw-w-fit">
                        <sidkik-icon class="alert" svgIcon="alert_circle"></sidkik-icon>
                      </div>
                    </div>
                  </a>
                  <a [routerLink]="['/integrations/quickbooks/config']" routerLinkActive="active"
                    class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Quickbooks
                    </div>
                  </a>
                  <!-- <a [routerLink]="['/integrations/active-campaign/config']" routerLinkActive="active"
                    class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      ActiveCampaign
                    </div>
                  </a> -->
                  <a [routerLink]="['/integrations/vimeo/config']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Vimeo
                    </div>
                  </a>
                  <a [routerLink]="['/integrations/mailer/config']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Mailer
                    </div>
                  </a>
                  <a [routerLink]="['/integrations/slack/config']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Slack
                    </div>
                  </a>
                  <a [routerLink]="['/integrations/zoom/config']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Zoom
                    </div>
                  </a>
                </div>
                <div [class.tw-hidden]="activeSection !== sections.Extras"
                  [class.tw-block]="activeSection === sections.Settings">
                  <div class="sidebar-section">Extras</div>
                  <a [routerLink]="['/config/accounting']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Accounting
                    </div>
                  </a>
                  <a [routerLink]="['/config/scheduling']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Scheduling
                    </div>
                  </a>
                  <a [routerLink]="['/config/addon']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Addons
                    </div>
                  </a>
                  <a [routerLink]="['/config/debug']" routerLinkActive="active" class="sidebar-item-main">
                    <div class="sidebar-item-main-link">
                      Debug
                    </div>
                  </a>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
    <sidkik-user-info></sidkik-user-info>
  </div>

  <!-- Main content and help panel container -->
  <div class="tw-flex tw-flex-1">
    <!-- Main content -->
    <div class="tw-flex tw-min-w-0 tw-flex-1 tw-flex-col tw-overflow-x-hidden tw-overflow-y-auto" cdkScrollable
      id="scrollable-container">
      <div class="lg:tw-hidden">
        <div
          class="tw-flex tw-items-center tw-justify-between tw-border-b tw-border-gray-200 tw-bg-gray-50 tw-px-4 tw-py-1.5">
          <div>
            <img class="tw-h-8 tw-w-auto" [src]="logoUrl" alt="Workflow">
          </div>
          <div class="tw-flex tw-items-center tw-gap-2">
            <!-- Menu Button -->
            <button type="button" (click)="toggleOffCanvasMenu()"
              class="-tw-mr-3 tw-inline-flex tw-h-12 tw-w-12 tw-items-center tw-justify-center tw-rounded-md tw-text-gray-500 hover:tw-text-gray-900">
              <span class="tw-sr-only">Open sidebar</span>
              <svg class="tw-h-6 tw-w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="tw-relative tw-z-0 tw-flex tw-flex-col tw-flex-grow">
        <!-- <sidkik-breadcrumb [trail]="breadcrumbTrail$ | async" [includeHome]="false"></sidkik-breadcrumb> -->
        <div class="tw-flex tw-flex-grow">
          <main class="tw-relative tw-z-0 tw-flex-1 tw-max-w-full focus:tw-outline-none">
            <div class="">
              <router-outlet></router-outlet>
            </div>
          </main>
        </div>
      </div>
      <div class="tw-mt-16 tw-flex  tw-p-6 sm:tw-mt-20 lg:tw-mt-24">
        <div class="tw-flex-grow"> </div>
        <p class="tw-text-xs tw-leading-5 tw-text-gray-300">Powered by: <a
            class="tw-text-gray-400 hover:tw-text-gray-500 tw-underline" href="https://sidkik.com"
            target="_blank">Sidkik</a> <span (click)="enableDebug()"> v{{version}}</span></p>
      </div>
    </div>

    <!-- Help Panel - Third Column for large screens -->
    <div class="2xl:tw-block tw-hidden tw-transition-all tw-duration-300 tw-ease-in-out tw-overflow-hidden"
      [class.tw-flex-1]="isHelpPanelOpen" [class.tw-w-0]="!isHelpPanelOpen"
      [ngClass]="{'tw-border-l tw-border-gray-200': isHelpPanelOpen}">
      <div
        class="tw-h-full tw-overflow-y-auto tw-w-full tw-relative tw-bg-white tw-transition-transform tw-duration-300 tw-ease-in-out"
        [ngClass]="{
          'tw-translate-x-0': isHelpPanelOpen,
          'tw-translate-x-full': !isHelpPanelOpen
        }">
        <!-- Close button -->
        <button type="button" (click)="closeHelpPanel()"
          class="tw-absolute tw-right-4 tw-top-4 tw-text-gray-400 hover:tw-text-gray-500 tw-z-10">
          <span class="tw-sr-only">Close help panel</span>
          <svg class="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <!-- Help Component -->
        <div class="tw-flex-1 tw-pt-16">
          <sidkik-help></sidkik-help>
        </div>
      </div>
    </div>
  </div>

  <!-- Help Panel - Slideover for small screens -->
  <div class="2xl:tw-hidden">
    <div class="tw-fixed tw-inset-0 tw-z-[100] tw-isolate tw-transition-all tw-duration-300 tw-ease-in-out"
      [ngClass]="{'tw-visible tw-opacity-100': isHelpPanelOpen, 'tw-invisible tw-opacity-0': !isHelpPanelOpen}">
      <!-- Background overlay -->
      <div class="tw-fixed tw-inset-0 tw-bg-gray-500 tw-transition-opacity tw-duration-300 tw-ease-in-out"
        [ngClass]="{'tw-bg-opacity-75': isHelpPanelOpen, 'tw-bg-opacity-0': !isHelpPanelOpen}"
        (click)="closeHelpPanel()">
      </div>

      <!-- Panel -->
      <div class="tw-fixed tw-inset-y-0 tw-right-0 tw-flex tw-max-w-full">
        <div
          class="tw-relative tw-w-screen tw-max-w-[75ch] tw-min-w-[24rem] tw-transition-all tw-duration-300 tw-ease-in-out tw-transform"
          [ngClass]="{'tw-translate-x-0': isHelpPanelOpen, 'tw-translate-x-full': !isHelpPanelOpen}">
          <div class="tw-flex tw-h-full tw-flex-col tw-overflow-y-scroll tw-bg-white tw-shadow-xl">
            <div class="tw-px-4 tw-py-6 sm:tw-px-6">
              <!-- Close button -->
              <div class="tw-flex tw-items-center tw-justify-end">
                <button type="button" (click)="closeHelpPanel()" class="tw-text-gray-400 hover:tw-text-gray-500">
                  <span class="tw-sr-only">Close help panel</span>
                  <svg class="tw-h-6 tw-w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
              <!-- Help Component -->
              <sidkik-help></sidkik-help>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>