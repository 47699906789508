import { ComboBoxOption } from '../../models';

export enum HelpTenantAppMap {
  notFound = 'notFound',
  assetsList = 'assetsList',
  assetsEdit = 'assetsEdit',
  assetsNoContent = 'assetsNoContent',
  coursesList = 'coursesList',
  coursesEdit = 'coursesEdit',
  coursesNoContent = 'coursesNoContent',
  challengesList = 'challengesList',
  challengesEdit = 'challengesEdit',
  challengesNoContent = 'challengesNoContent',
  postsList = 'postsList',
  postsEdit = 'postsEdit',
  postsNoContent = 'postsNoContent',
  postTagsList = 'postTagsList',
  postTagsEdit = 'postTagsEdit',
  postTagsNoContent = 'postTagsNoContent',
  helpTagsList = 'helpTagsList',
  helpTagsEdit = 'helpTagsEdit',
  helpTagsNoContent = 'helpTagsNoContent',
  helpList = 'helpList',
  helpEdit = 'helpEdit',
  helpNoContent = 'helpNoContent',
  pagesList = 'pagesList',
  pagesEdit = 'pagesEdit',
  pagesNoContent = 'pagesNoContent',
  flowsList = 'flowsList',
  flowsEdit = 'flowsEdit',
  flowsNoContent = 'flowsNoContent',
  emailsList = 'emailsList',
  emailsEdit = 'emailsEdit',
  emailsNoContent = 'emailsNoContent',
  formsList = 'formsList',
  formsEdit = 'formsEdit',
  formsNoContent = 'formsNoContent',
  automationsList = 'automationsList',
  automationsEdit = 'automationsEdit',
  automationsNoContent = 'automationsNoContent',
  campaignsList = 'campaignsList',
  campaignsEdit = 'campaignsEdit',
  campaignsNoContent = 'campaignsNoContent',
  campaignsRunsList = 'campaignsRunsList',
  campaignsRunsEdit = 'campaignsRunsEdit',
  campaignsRunsNoContent = 'campaignsRunsNoContent',
  customerListsList = 'customerListsList',
  customerListsEdit = 'customerListsEdit',
  customerListsNoContent = 'customerListsNoContent',
  customerTagsList = 'customerTagsList',
  customerTagsEdit = 'customerTagsEdit',
  customerTagsNoContent = 'customerTagsNoContent',
  spacesList = 'spacesList',
  spacesEdit = 'spacesEdit',
  spacesNoContent = 'spacesNoContent',
  commentsList = 'commentsList',
  commentsNoContent = 'commentsNoContent',
  threadsList = 'threadsList',
  threadsNoContent = 'threadsNoContent',
  communityConfigurationEdit = 'communityConfigurationEdit',
  schedulingCalendar = 'schedulingCalendar',
  schedulingAvailability = 'schedulingAvailability',
  schedulingSettings = 'schedulingSettings',
  productsList = 'productsList',
  productsEdit = 'productsEdit',
  productsNoContent = 'productsNoContent',
  pricesEdit = 'pricesEdit',
  couponsList = 'couponsList',
  couponsEdit = 'couponsEdit',
  couponsNoContent = 'couponsNoContent',
  customersList = 'customersList',
  customersDetails = 'customersDetails',
  customersNoContent = 'customersNoContent',
  affiliatesList = 'affiliatesList',
  affiliatesDetails = 'affiliatesDetails',
  affiliatesNoContent = 'affiliatesNoContent',
  usersList = 'usersList',
  usersDetails = 'usersDetails',
  usersNoContent = 'usersNoContent',
  themeConfigurationEdit = 'themeConfigurationEdit',
  domainConfigurationEdit = 'domainConfigurationEdit',
  navigationConfigurationEdit = 'navigationConfigurationEdit',
  stripeConfigurationEdit = 'stripeConfigurationEdit',
  quickbooksConfigurationEdit = 'quickbooksConfigurationEdit',
  vimeoConfigurationEdit = 'vimeoConfigurationEdit',
  mailerConfigurationEdit = 'mailerConfigurationEdit',
  slackConfigurationEdit = 'slackConfigurationEdit',
  zoomConfigurationEdit = 'zoomConfigurationEdit',
  pricesNoContent = 'pricesNoContent',
  accountingConfigurationEdit = 'accountingConfigurationEdit',
  addonsConfigurationEdit = 'addonsConfigurationEdit',
}

export const helpContextualMapLookups: ComboBoxOption[] = [
  {
    label: 'Help List',
    value: HelpTenantAppMap.helpList,
  },
  {
    label: 'Help Edit',
    value: HelpTenantAppMap.helpEdit,
  },
  {
    label: 'Help No Content',
    value: HelpTenantAppMap.helpNoContent,
  },
  {
    label: 'Accounting Configuration Edit',
    value: HelpTenantAppMap.accountingConfigurationEdit,
  },
  {
    label: 'Addons Configuration Edit',
    value: HelpTenantAppMap.addonsConfigurationEdit,
  },
  {
    label: 'Assets List',
    value: HelpTenantAppMap.assetsList,
  },
  {
    label: 'Assets Edit',
    value: HelpTenantAppMap.assetsEdit,
  },
  {
    label: 'Courses List',
    value: HelpTenantAppMap.coursesList,
  },
  {
    label: 'Courses Edit',
    value: HelpTenantAppMap.coursesEdit,
  },
  {
    label: 'Challenges List',
    value: HelpTenantAppMap.challengesList,
  },
  {
    label: 'Challenges Edit',
    value: HelpTenantAppMap.challengesEdit,
  },
  {
    label: 'Posts List',
    value: HelpTenantAppMap.postsList,
  },
  {
    label: 'Posts Edit',
    value: HelpTenantAppMap.postsEdit,
  },
  {
    label: 'Post Tags List',
    value: HelpTenantAppMap.postTagsList,
  },
  {
    label: 'Help Tags List',
    value: HelpTenantAppMap.helpTagsList,
  },
  {
    label: 'Help Tags Edit',
    value: HelpTenantAppMap.helpTagsEdit,
  },
  {
    label: 'Help Tags No Content',
    value: HelpTenantAppMap.helpTagsNoContent,
  },
  {
    label: 'Pages List',
    value: HelpTenantAppMap.pagesList,
  },
  {
    label: 'Pages Edit',
    value: HelpTenantAppMap.pagesEdit,
  },
  {
    label: 'Flows List',
    value: HelpTenantAppMap.flowsList,
  },
  {
    label: 'Flows Edit',
    value: HelpTenantAppMap.flowsEdit,
  },
  {
    label: 'Emails List',
    value: HelpTenantAppMap.emailsList,
  },
  {
    label: 'Emails Edit',
    value: HelpTenantAppMap.emailsEdit,
  },
  {
    label: 'Forms List',
    value: HelpTenantAppMap.formsList,
  },
  {
    label: 'Forms Edit',
    value: HelpTenantAppMap.formsEdit,
  },
  {
    label: 'Automations List',
    value: HelpTenantAppMap.automationsList,
  },
  {
    label: 'Automations Edit',
    value: HelpTenantAppMap.automationsEdit,
  },
  {
    label: 'Campaigns List',
    value: HelpTenantAppMap.campaignsList,
  },
  {
    label: 'Campaigns Edit',
    value: HelpTenantAppMap.campaignsEdit,
  },
  {
    label: 'Campaigns Runs List',
    value: HelpTenantAppMap.campaignsRunsList,
  },
  {
    label: 'Campaigns Runs Edit',
    value: HelpTenantAppMap.campaignsRunsEdit,
  },
  {
    label: 'Customer Lists List',
    value: HelpTenantAppMap.customerListsList,
  },
  {
    label: 'Customer Lists Edit',
    value: HelpTenantAppMap.customerListsEdit,
  },
  {
    label: 'Customer Tags List',
    value: HelpTenantAppMap.customerTagsList,
  },
  {
    label: 'Customer Tags Edit',
    value: HelpTenantAppMap.customerTagsEdit,
  },
  {
    label: 'Spaces List',
    value: HelpTenantAppMap.spacesList,
  },
  {
    label: 'Spaces Edit',
    value: HelpTenantAppMap.spacesEdit,
  },
  {
    label: 'Comments List',
    value: HelpTenantAppMap.commentsList,
  },
  {
    label: 'Threads List',
    value: HelpTenantAppMap.threadsList,
  },
  {
    label: 'Community Configuration Edit',
    value: HelpTenantAppMap.communityConfigurationEdit,
  },
  {
    label: 'Scheduling Calendar',
    value: HelpTenantAppMap.schedulingCalendar,
  },
  {
    label: 'Scheduling Availability',
    value: HelpTenantAppMap.schedulingAvailability,
  },
  {
    label: 'Scheduling Settings',
    value: HelpTenantAppMap.schedulingSettings,
  },
  {
    label: 'Products List',
    value: HelpTenantAppMap.productsList,
  },
  {
    label: 'Products Edit',
    value: HelpTenantAppMap.productsEdit,
  },
  {
    label: 'Prices Edit',
    value: HelpTenantAppMap.pricesEdit,
  },
  {
    label: 'Prices No Content',
    value: HelpTenantAppMap.pricesNoContent,
  },
  {
    label: 'Coupons List',
    value: HelpTenantAppMap.couponsList,
  },
  {
    label: 'Coupons Edit',
    value: HelpTenantAppMap.couponsEdit,
  },
  {
    label: 'Customers List',
    value: HelpTenantAppMap.customersList,
  },
  {
    label: 'Customers Details',
    value: HelpTenantAppMap.customersDetails,
  },
  {
    label: 'Affiliates List',
    value: HelpTenantAppMap.affiliatesList,
  },
  {
    label: 'Affiliates Details',
    value: HelpTenantAppMap.affiliatesDetails,
  },
  {
    label: 'Users List',
    value: HelpTenantAppMap.usersList,
  },
  {
    label: 'Users Details',
    value: HelpTenantAppMap.usersDetails,
  },
  {
    label: 'Theme Configuration Edit',
    value: HelpTenantAppMap.themeConfigurationEdit,
  },
  {
    label: 'Domain Configuration Edit',
    value: HelpTenantAppMap.domainConfigurationEdit,
  },
  {
    label: 'Navigation Configuration Edit',
    value: HelpTenantAppMap.navigationConfigurationEdit,
  },
  {
    label: 'Stripe Configuration Edit',
    value: HelpTenantAppMap.stripeConfigurationEdit,
  },
  {
    label: 'Quickbooks Configuration Edit',
    value: HelpTenantAppMap.quickbooksConfigurationEdit,
  },
  {
    label: 'Vimeo Configuration Edit',
    value: HelpTenantAppMap.vimeoConfigurationEdit,
  },
  {
    label: 'Mailer Configuration Edit',
    value: HelpTenantAppMap.mailerConfigurationEdit,
  },
  {
    label: 'Slack Configuration Edit',
    value: HelpTenantAppMap.slackConfigurationEdit,
  },
  {
    label: 'Zoom Configuration Edit',
    value: HelpTenantAppMap.zoomConfigurationEdit,
  },

  {
    label: 'Assets No Content',
    value: HelpTenantAppMap.assetsNoContent,
  },
  {
    label: 'Courses No Content',
    value: HelpTenantAppMap.coursesNoContent,
  },
  {
    label: 'Challenges No Content',
    value: HelpTenantAppMap.challengesNoContent,
  },
  {
    label: 'Posts No Content',
    value: HelpTenantAppMap.postsNoContent,
  },
  {
    label: 'Pages No Content',
    value: HelpTenantAppMap.pagesNoContent,
  },
  {
    label: 'Flows No Content',
    value: HelpTenantAppMap.flowsNoContent,
  },
  {
    label: 'Emails No Content',
    value: HelpTenantAppMap.emailsNoContent,
  },
  {
    label: 'Forms No Content',
    value: HelpTenantAppMap.formsNoContent,
  },
  {
    label: 'Automations No Content',
    value: HelpTenantAppMap.automationsNoContent,
  },
  {
    label: 'Campaigns No Content',
    value: HelpTenantAppMap.campaignsNoContent,
  },
  {
    label: 'Customer Lists No Content',
    value: HelpTenantAppMap.customerListsNoContent,
  },
  {
    label: 'Customer Tags No Content',
    value: HelpTenantAppMap.customerTagsNoContent,
  },
  {
    label: 'Spaces No Content',
    value: HelpTenantAppMap.spacesNoContent,
  },
  {
    label: 'Comments No Content',
    value: HelpTenantAppMap.commentsNoContent,
  },
  {
    label: 'Threads No Content',
    value: HelpTenantAppMap.threadsNoContent,
  },
  {
    label: 'Products No Content',
    value: HelpTenantAppMap.productsNoContent,
  },
  {
    label: 'Coupons No Content',
    value: HelpTenantAppMap.couponsNoContent,
  },
  {
    label: 'Customers No Content',
    value: HelpTenantAppMap.customersNoContent,
  },
  {
    label: 'Affiliates No Content',
    value: HelpTenantAppMap.affiliatesNoContent,
  },
  {
    label: 'Users No Content',
    value: HelpTenantAppMap.usersNoContent,
  },
  {
    label: 'Not Found',
    value: HelpTenantAppMap.notFound,
  },
];

export enum HelpType {
  contextual = 'contextual',
  library = 'library',
}

export const helpTypeLookups: ComboBoxOption[] = [
  {
    label: 'Contextual',
    value: HelpType.contextual,
    description:
      'Help that is contextually relevant to the current page or feature. Found only in app.',
  },
  {
    label: 'Library',
    value: HelpType.library,
    description:
      'Help that is relevant to the entire application. Searchable and found in the help center.',
  },
];
