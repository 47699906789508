import { Injectable, OnDestroy } from '@angular/core';
import { DbService } from '@sidkik/db';
import { HelpPanelService } from '@sidkik/global';
import { AutoDestroy } from '@sidkik/shared';
import {
  BehaviorSubject,
  distinctUntilChanged,
  map,
  of,
  Subscription,
  switchMap,
  take,
  tap,
} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class HelpContentLoaderService implements OnDestroy {
  @AutoDestroy()
  helpContent$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  currentHelpMapSubscription: Subscription;
  isLoading = false;
  constructor(
    private dbService: DbService,
    private helpPanelService: HelpPanelService
  ) {
    this.currentHelpMapSubscription = this.helpPanelService.currentHelpMap$
      .pipe(
        distinctUntilChanged(), // Prevent duplicate emissions
        switchMap((currentMap) => {
          logger.trace(
            'tenant-layout:help-content-loader',
            'currentHelpMap item',
            currentMap
          );
          if (currentMap) {
            if (this.isLoading) {
              return of(null);
            }
            this.isLoading = true;
            return this.dbService.getContextualHelp(currentMap).pipe(
              take(1),
              tap(() => (this.isLoading = false)),
              map((help) => {
                if (help) {
                  logger.trace(
                    'tenant-layout:help-content-loader',
                    'found help content for ',
                    currentMap,
                    help
                  );
                  return this.helpContent$.next(help.data.content);
                }
                logger.trace(
                  'tenant-layout:help-content-loader',
                  'no help content found for ',
                  currentMap
                );
                return this.helpContent$.next('');
              })
            );
          }
          logger.trace(
            'tenant-layout:help-content-loader',
            'no currentHelpMap$'
          );
          this.helpContent$.next('');
          return of('');
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.currentHelpMapSubscription?.unsubscribe();
  }
}
