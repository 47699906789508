import {
  AutomationActivityType,
  AutomationConditionalType,
  AutomationNodeType,
} from './automation-types';

export interface NodeTypeInfo {
  label: string;
  icon: string; // This could be a Material icon name, Font Awesome class, etc.
  description?: string;
}

export const NODE_TYPE_LOOKUP: Record<AutomationNodeType, NodeTypeInfo> = {
  [AutomationNodeType.Unknown]: {
    label: 'Unknown',
    icon: '',
    description: 'Unknown node type',
  },
  [AutomationNodeType.Activity]: {
    label: 'Activity',
    icon: '',
    description: 'Performs an action',
  },
  [AutomationNodeType.Conditional]: {
    label: 'Conditional',
    icon: '',
    description: 'Controls flow based on conditions',
  },
  [AutomationNodeType.Wait]: {
    label: 'Wait',
    icon: 'stop_watch',
    description: 'Pauses the flow for a specified duration',
  },
  [AutomationNodeType.Trigger]: {
    label: 'Trigger',
    icon: 'event',
    description: 'Starts the automation',
  },
  [AutomationNodeType.Start]: {
    label: 'Start',
    icon: 'go_flag',
    description: 'Entry point of the automation',
  },
};

export const ACTIVITY_TYPE_LOOKUP: Record<
  AutomationActivityType,
  NodeTypeInfo
> = {
  [AutomationActivityType.AddPermissions]: {
    label: 'Add Permissions',
    icon: 'add_permissions',
    description: 'Adds permissions to the customer',
  },
  [AutomationActivityType.RemovePermissions]: {
    label: 'Remove Permissions',
    icon: 'remove_permissions',
    description: 'Removes permissions from the customer',
  },
  [AutomationActivityType.SendEmail]: {
    label: 'Send Email',
    icon: 'send_email',
    description: 'Sends an email to specified recipients',
  },
  [AutomationActivityType.SlackMessage]: {
    label: 'Slack Notification',
    icon: 'slack',
    description: 'Sends a notification to your internal slack channel',
  },
  [AutomationActivityType.AddTag]: {
    label: 'Add Tag',
    icon: 'add_tag',
    description: 'Adds a tag to the entity',
  },
  [AutomationActivityType.RemoveTag]: {
    label: 'Remove Tag',
    icon: 'remove_tag',
    description: 'Removes a tag from the entity',
  },
  [AutomationActivityType.AddList]: {
    label: 'Add to List',
    icon: 'add_list',
    description: 'Adds entity to a list',
  },
  [AutomationActivityType.RemoveList]: {
    label: 'Remove from List',
    icon: 'remove_list',
    description: 'Removes entity from a list',
  },
  [AutomationActivityType.CancelTrialWait]: {
    label: 'Cancel Trial Wait',
    icon: 'cancel_alarm',
    description: 'Cancels trial waiting period',
  },
  [AutomationActivityType.ConvertTrialToPaid]: {
    label: 'Convert Trial to Paid',
    icon: 'convert_customer',
    description: 'Converts trial to paid subscription',
  },
  [AutomationActivityType.ProvisionPlatformTenant]: {
    label: 'Provision Tenant',
    icon: 'provision_tenant',
    description: 'Creates a new platform tenant',
  },
  [AutomationActivityType.ProvisionPlatformTenantTrial]: {
    label: 'Provision Trial Tenant',
    icon: 'provision_trial',
    description: 'Creates a new trial platform tenant',
  },
};

export const CONDITIONAL_TYPE_LOOKUP: Record<
  AutomationConditionalType,
  NodeTypeInfo
> = {
  [AutomationConditionalType.Binary]: {
    label: 'Yes/No',
    icon: 'conditional_binary',
    description: 'True/False branching',
  },
  [AutomationConditionalType.Multi]: {
    label: 'Split Path',
    icon: 'conditional_multi',
    description: 'Multiple outcome paths',
  },
};

// Helper functions to get info
export const getNodeTypeInfo = (type: AutomationNodeType): NodeTypeInfo =>
  NODE_TYPE_LOOKUP[type] ?? { label: 'Unknown', icon: '' };

export const getActivityTypeInfo = (
  type: AutomationActivityType
): NodeTypeInfo => ACTIVITY_TYPE_LOOKUP[type];

export const getConditionalTypeInfo = (
  type: AutomationConditionalType
): NodeTypeInfo => CONDITIONAL_TYPE_LOOKUP[type];
