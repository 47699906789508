import { Storage, StorageProperties } from '../storage';
import { Meta } from '../meta';
import { EntityType } from '@sidkik/global';

export interface AddonConfigData {
  tawkTo?: {
    chatLink?: string;
    key?: string;
  };
  activeCampaignId?: string;
  linkedInInsightsId?: string;
  fbPixelId?: string;
  clarityId?: string;
  googleMeasurementId?: string;
}

export interface AddonConfigProperties extends StorageProperties {
  data: AddonConfigData;
  meta: Meta;
  id: string;
}

export class AddonConfig extends Storage implements AddonConfigProperties {
  public override data!: AddonConfigData;

  constructor(options?: AddonConfigProperties, user?: string) {
    super(options, user, EntityType.AddonConfiguration);
    this.id = 'addon';
    if (options?.data) this.update(options?.data);
  }

  public update(data: AddonConfigData): void {
    this.data = { ...this.data, ...data };
  }
}
