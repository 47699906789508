import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType, AutomationStats } from '@sidkik/global';

export interface AutomationData {
  title: string;
  active: boolean;
  multipleEntries: boolean;
  source?: string;
  stats?: AutomationStats;
}

export interface AutomationProperties extends StorageProperties {
  data: AutomationData;
  meta: Meta;
  id: string;
}

export class Automation extends Storage implements AutomationProperties {
  public override data!: AutomationData;

  constructor(options?: AutomationProperties, user?: string) {
    super(options, user, EntityType.Automation);
    this.update(options?.data);
  }

  public update(data?: AutomationData): void {
    this.data = { ...data } as unknown as AutomationData;
  }
}
