import { AutomationCondition } from './automation-conditionals';
import { AutomationNode, AutomationEdge } from './automation-nodes';

export interface AutomationStats {
  automationId: string;
  complete: number;
  error: number;
  running: number;
}

export interface AutomationState {
  [key: string]: string;
}

export interface Automation {
  id: string;
  nodes: { [id: string]: AutomationNode };
  edges: AutomationEdge[];
  variables: {
    tenant: string;
    customer: string;
  };
}

export interface NodeExecution {
  nodeId: string;
  nodeType: string;
  data: any;
  bindings: { [key: string]: string };
  timestamp: string;
  metadata: { [key: string]: any };
  status: ExecutionStatus;
  error?: string;
  enteredAt: number;
  exitedAt: number;
  duration: number;
  waitTime?: number;
  fromNode?: string;
  fromPort?: string;
  conditionEvaluationResults?: ConditionalEvaluationResult[];
}

export interface ConditionalEvaluationResult {
  name: string;
  passed: boolean;
  logicalOperator: string;
  conditionCheckValues: ConditionCheckValue[];
}

export interface ConditionCheckValue {
  passed: boolean;
  condition: AutomationCondition;
}

export interface AutomationExecution {
  id: string;
  nodes: { [id: string]: AutomationNode };
  edges: AutomationEdge[];
  variables: {
    tenant: string;
    customer: string;
  };
  status: AutomationStatus;
  error?: string;
  startedAt: number;
  executions: NodeExecution[];
  lastNodeId: string;
}

export enum AutomationStatus {
  Started = 'started',
  Complete = 'complete',
  Error = 'error',
  Cancelled = 'cancelled',
}

export enum ExecutionStatus {
  Started = 'started',
  Complete = 'complete',
  Error = 'error',
  Cancelled = 'cancelled',
}
