export const loadBasicWebAnalyticsType = '[analytics] load basic web analytics';
export const loadBasicWebAnalyticsSuccessType =
  '[analytics] load basic web analytics success';
export const loadBasicWebAnalyticsFailureType =
  '[analytics] load basic web analytics failure';

// Sessions
export const loadGlobalSessionMetricsType =
  '[analytics/sessions] load global session metrics';
export const loadGlobalSessionMetricsSuccessType =
  '[analytics/sessions] load global session metrics success';
export const loadGlobalSessionMetricsFailureType =
  '[analytics/sessions] load global session metrics failure';

export const loadFirstSourceFirstMediumByDatesType =
  '[analytics/sessions] load first source first medium by dates';
export const loadFirstSourceFirstMediumByDatesSuccessType =
  '[analytics/sessions] load first source first medium by dates success';
export const loadFirstSourceFirstMediumByDatesFailureType =
  '[analytics/sessions] load first source first medium by dates failure';

export const setDateRangeType = '[analytics/date-range] set date range';

export const loadFirstSourceFirstMediumAggregatedForRangeType =
  '[analytics/sessions] load first source first medium aggregated for range';
export const loadFirstSourceFirstMediumAggregatedForRangeSuccessType =
  '[analytics/sessions] load first source first medium aggregated for range success';
export const loadFirstSourceFirstMediumAggregatedForRangeFailureType =
  '[analytics/sessions] load first source first medium aggregated for range failure';

// Ecommerce
export const loadProductPerformanceForRangeType =
  '[analytics/ecommerce] load product performance for range';
export const loadProductPerformanceForRangeSuccessType =
  '[analytics/ecommerce] load product performance for range success';
export const loadProductPerformanceForRangeFailureType =
  '[analytics/ecommerce] load product performance for range failure';

export const loadCouponPerformanceByDateRangeType =
  '[analytics/ecommerce] load coupon performance by date range';
export const loadCouponPerformanceByDateRangeSuccessType =
  '[analytics/ecommerce] load coupon performance by date range success';
export const loadCouponPerformanceByDateRangeFailureType =
  '[analytics/ecommerce] load coupon performance by date range failure';

export const loadRevenueByDayType = '[analytics/ecommerce] load revenue by day';
export const loadRevenueByDaySuccessType =
  '[analytics/ecommerce] load revenue by day success';
export const loadRevenueByDayFailureType =
  '[analytics/ecommerce] load revenue by day failure';

export const loadPagePerformanceType =
  '[analytics/sessions] load page performance';
export const loadPagePerformanceSuccessType =
  '[analytics/sessions] load page performance success';
export const loadPagePerformanceFailureType =
  '[analytics/sessions] load page performance failure';

export const loadLandingPageType = '[analytics/sessions] load landing page';
export const loadLandingPageSuccessType =
  '[analytics/sessions] load landing page success';
export const loadLandingPageFailureType =
  '[analytics/sessions] load landing page failure';

export const loadStickySessionType = '[analytics/sessions] load sticky session';
export const loadStickySessionSuccessType =
  '[analytics/sessions] load sticky session success';
export const loadStickySessionFailureType =
  '[analytics/sessions] load sticky session failure';

export const loadPeriodPagePerformanceType =
  '[analytics/sessions] load period page performance';
export const loadPeriodPagePerformanceSuccessType =
  '[analytics/sessions] load period page performance success';
export const loadPeriodPagePerformanceFailureType =
  '[analytics/sessions] load period page performance failure';

export const loadPeriodLandingPageType =
  '[analytics/sessions] load period landing page';
export const loadPeriodLandingPageSuccessType =
  '[analytics/sessions] load period landing page success';
export const loadPeriodLandingPageFailureType =
  '[analytics/sessions] load period landing page failure';

export const selectPagePerformancePageUrlType =
  '[analytics/sessions] select page performance page url';

export const selectLandingPagePageUrlType =
  '[analytics/sessions] select landing page page url';

export const unselectPagePerformancePageUrlType =
  '[analytics/sessions] unselect page performance page url';

export const unselectLandingPagePageUrlType =
  '[analytics/sessions] unselect landing page page url';

// Customer
export const loadCustomerBreakoutAllTimeTotalsType =
  '[analytics/customer] load customer breakout all time totals';
export const loadCustomerBreakoutAllTimeTotalsSuccessType =
  '[analytics/customer] load customer breakout all time totals success';
export const loadCustomerBreakoutAllTimeTotalsFailureType =
  '[analytics/customer] load customer breakout all time totals failure';
