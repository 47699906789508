import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { Integrations } from '../integrations';
import { Permissions } from './permissions';
import { CustomerType, EntityType, PlatformStatus } from '@sidkik/global';
import { CXProperties } from './cx';
export interface Marketing {
  tags?: string[];
  lists?: string[];
  unsubscribes?: string[];
  doNotContact?: boolean;
  doNotContactDate?: number;
}

export interface CustomerData {
  displayName?: string;
  email?: string;
  roles?: string[];
  permissions?: Permissions;
  marketing?: Marketing;
  emailVerified?: boolean;
  doiReceived?: number;
  soiReceived?: number;
  isDoubleOptIn?: boolean;
  isSingleOptIn?: boolean;
  customerType: CustomerType;
  isConvertedSubscriber?: boolean;
  isTrialPlatform?: boolean;
  trialPlatformExpiry?: number;
  platformStatus?: PlatformStatus;
  avatar?: string;
}

export interface CustomerProperties extends StorageProperties {
  data: CustomerData;
  meta: Meta;
  id: string;
  integrations: Integrations;
}

export class Customer extends Storage implements CustomerProperties {
  public override data!: CustomerData;
  constructor(options?: CustomerProperties, user?: string) {
    super(options, user, EntityType.Customer);
    this.update(options?.data);
  }

  public update(data?: CustomerData): void {
    this.data = { ...data } as unknown as CustomerData;
  }

  public setPermissions(permissions: Permissions): void {
    this.data.permissions = permissions;
  }

  public markDoNotContact(): void {
    if (!this.data.marketing) {
      this.data.marketing = {};
    }
    this.data.marketing.doNotContact = true;
    this.data.marketing.doNotContactDate = new Date().getTime();
  }

  public unmarkDoNotContact(): void {
    if (!this.data.marketing) {
      this.data.marketing = {};
    }
    this.data.marketing.doNotContact = false;
    this.data.marketing.doNotContactDate = 0;
  }

  public addTag(tag: string): void {
    if (!this.data.marketing) {
      this.data.marketing = {};
    }
    if (!this.data.marketing?.tags) {
      this.data.marketing.tags = [];
    }
    this.data.marketing.tags.push(tag);
    this.meta.updated = 0; // local update only so need to go back to get server update incase of time conflict
  }

  public removeTag(tag: string): void {
    if (this.data.marketing?.tags) {
      const index = this.data.marketing.tags.indexOf(tag);
      if (index > -1) {
        this.data.marketing.tags.splice(index, 1);
      }
      this.meta.updated = 0; // local update only so need to go back to get server update incase of time conflict
    }
  }

  public addList(list: string): void {
    if (!this.data.marketing) {
      this.data.marketing = {};
    }
    if (!this.data.marketing?.lists) {
      this.data.marketing.lists = [];
    }
    this.data.marketing.lists.push(list);
    this.meta.updated = 0; // local update only so need to go back to get server update incase of time conflict
  }

  public removeList(list: string): void {
    if (this.data.marketing?.lists) {
      const index = this.data.marketing.lists.findIndex((l) => l === list);
      if (index > -1) {
        this.data.marketing.lists.splice(index, 1);
      }
      this.meta.updated = 0; // local update only so need to go back to get server update incase of time conflict
    }
  }
}

export interface CustomerWithCXProperties extends CustomerProperties {
  cx?: CXProperties;
}
