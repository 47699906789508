import { Component, OnInit } from '@angular/core';
import { BaseDestroyComponent } from '@sidkik/shared';
import { Observable } from 'rxjs';
import { HelpContentLoaderService } from '../../services/help-content-loader.service';

@Component({
  selector: 'sidkik-help',
  templateUrl: './help.component.html',
})
export class HelpComponent extends BaseDestroyComponent implements OnInit {
  helpContent$!: Observable<string>;

  constructor(private helpContentLoaderService: HelpContentLoaderService) {
    super();
  }

  ngOnInit() {
    this.helpContent$ = this.helpContentLoaderService.helpContent$;
  }
}
