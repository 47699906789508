import { Meta } from '../meta';
import { StorageProperties, Storage, omitUndefinedProps } from '../storage';
import { EntityType, HelpType, HelpTenantAppMap } from '@sidkik/global';
import { AssetProperties } from './asset';
import { ContentSection, HelpMapEntry } from './help-map';
import { MetaData } from './metadata';

export interface HelpData {
  title: string;
  type: HelpType;
  contextualMap?: HelpTenantAppMap;
  content: string; // EditorJS data
  tags?: string[];
  slug?: string;
  publishDate?: number;
  excerpt?: string;
  featured?: boolean;
  video?: AssetProperties;
  image?: AssetProperties;
  metadata?: MetaData;
  associatedContent?: ContentSection[];
}

export interface HelpProperties extends StorageProperties {
  data: HelpData;
  meta: Meta;
  id: string;
}

export interface BundledHelpProperties extends StorageProperties {
  data: HelpData;
  meta: Meta;
  id: string;
}

export class Help extends Storage implements HelpProperties {
  public override data!: HelpData;

  constructor(options?: HelpProperties, user?: string) {
    super(options, user, EntityType.Help);
    this.update(options?.data);
  }

  public update(data?: HelpData): void {
    this.data = { ...data } as unknown as HelpData;
    this.data.content = this.data.content || '';
  }

  public toMapEntry(): HelpMapEntry {
    return omitUndefinedProps({
      id: this.id,
      title: this.data.title,
      slug: this.data.slug,
      tags: this.data.tags,
      publishDate: this.data.publishDate,
      video: this.data.video,
      image: this.data.image,
      excerpt: this.data.excerpt,
      featured: this.data.featured,
    });
  }
}
