import { Meta } from '../meta';
import { StorageProperties, Storage } from '../storage';
import { EntityType } from '@sidkik/global';
import { PageLayout } from './page-layout';
import { AssetProperties } from './asset';

export interface HelpTagData {
  title: string;
  slug: string;
  isDefault?: boolean;
  useDefault?: boolean;
  content?: PageLayout;
  overrideImage?: boolean;
  image?: AssetProperties;
  allowOverflow?: boolean;
}

export interface HelpTagProperties extends StorageProperties {
  data: HelpTagData;
  meta: Meta;
  id: string;
}

export class HelpTag extends Storage implements HelpTagProperties {
  public override data!: HelpTagData;

  constructor(options?: HelpTagProperties, user?: string) {
    super(options, user, EntityType.HelpTag);
    this.update(options?.data);
  }

  public update(data?: HelpTagData): void {
    this.data = { ...data } as unknown as HelpTagData;
    this.data.content = this.data.content || {};
  }
}
