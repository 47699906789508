import {
  AutomationTargetType,
  AutomationBaseDefinition,
} from './automation-base-types';
import { EntityType } from '../../constants';

export interface AutomationTriggerDefinition extends AutomationBaseDefinition {
  unfiltered?: boolean;
  platformOnly?: boolean;
}

// not implemented or not applicable
type ExcludeAutomationTargetTypeTrigger =
  | AutomationTargetType.CourseCompletedPercentage
  | AutomationTargetType.CourseViewedPercentage
  | AutomationTargetType.ChallengeCompletedPercentage
  | AutomationTargetType.SubscriptionRenewal
  | AutomationTargetType.FormFieldChecked
  | AutomationTargetType.AffiliateCreated
  | AutomationTargetType.IsTenantTrial
  | AutomationTargetType.WasCustomerTrial
  | AutomationTargetType.Tag
  | AutomationTargetType.List
  | AutomationTargetType.CourseAccess
  | AutomationTargetType.ChallengeAccess
  | AutomationTargetType.MembershipAccess
  | AutomationTargetType.IsMember
  | AutomationTargetType.IsSubscriber;

export const AutomationTriggerDefinitions: Record<
  Exclude<AutomationTargetType, ExcludeAutomationTargetTypeTrigger>,
  AutomationTriggerDefinition
> = {
  [AutomationTargetType.Unsubscribed]: {
    name: 'Unsubscribed',
    target: AutomationTargetType.Unsubscribed,
    entity: EntityType.Customer,
    unfiltered: true,
  },
  [AutomationTargetType.DoNotContactMarked]: {
    name: 'Do Not Contact Marked',
    target: AutomationTargetType.DoNotContactMarked,
    entity: EntityType.Customer,
    unfiltered: true,
  },
  [AutomationTargetType.DoNotContactUnmarked]: {
    name: 'Do Not Contact Unmarked',
    target: AutomationTargetType.DoNotContactUnmarked,
    entity: EntityType.Customer,
    unfiltered: true,
  },
  [AutomationTargetType.PageViewed]: {
    name: 'Page Viewed',
    target: AutomationTargetType.PageViewed,
    entity: EntityType.Page,
    unfiltered: false,
  },
  [AutomationTargetType.PostViewed]: {
    name: 'Post Viewed',
    target: AutomationTargetType.PostViewed,
    entity: EntityType.Post,
    unfiltered: false,
  },
  [AutomationTargetType.EmailConfirmed]: {
    name: 'Email Confirmed',
    target: AutomationTargetType.EmailConfirmed,
    entity: EntityType.Customer,
    unfiltered: true,
  },
  [AutomationTargetType.PlatformAssigned]: {
    name: 'Platform Assigned',
    target: AutomationTargetType.PlatformAssigned,
    entity: EntityType.Tenant,
    unfiltered: true,
    platformOnly: true,
  },
  [AutomationTargetType.CourseSectionCompleted]: {
    name: 'Course Section Completed',
    target: AutomationTargetType.CourseSectionCompleted,
    entity: EntityType.Course,
    unfiltered: false,
  },
  [AutomationTargetType.CourseSectionViewed]: {
    name: 'Course Section Viewed',
    target: AutomationTargetType.CourseSectionViewed,
    entity: EntityType.Course,
    unfiltered: false,
  },
  [AutomationTargetType.ProductPurchased]: {
    name: 'Product Purchased',
    target: AutomationTargetType.ProductPurchased,
    entity: EntityType.Product,
    unfiltered: false,
  },

  [AutomationTargetType.ChallengeEventAdded]: {
    name: 'Challenge Activity Added',
    target: AutomationTargetType.ChallengeEventAdded,
    entity: EntityType.Challenge,
    unfiltered: false,
  },

  [AutomationTargetType.EmailMailOpened]: {
    name: 'Email Opened',
    target: AutomationTargetType.EmailMailOpened,
    entity: EntityType.Email,
    unfiltered: false,
  },
  [AutomationTargetType.EmailMailClicked]: {
    name: 'Email Clicked',
    target: AutomationTargetType.EmailMailClicked,
    entity: EntityType.Email,
    unfiltered: false,
  },
  [AutomationTargetType.CampaignMailOpened]: {
    name: 'Campaign Opened',
    target: AutomationTargetType.CampaignMailOpened,
    entity: EntityType.Campaign,
    unfiltered: false,
  },
  [AutomationTargetType.CampaignMailClicked]: {
    name: 'Campaign Clicked',
    target: AutomationTargetType.CampaignMailClicked,
    entity: EntityType.Campaign,
    unfiltered: false,
  },
  [AutomationTargetType.TagAdded]: {
    name: 'Tag Added',
    target: AutomationTargetType.TagAdded,
    entity: EntityType.CustomerTag,
    unfiltered: false,
  },
  [AutomationTargetType.TagRemoved]: {
    name: 'Tag Removed',
    target: AutomationTargetType.TagRemoved,
    entity: EntityType.CustomerTag,
    unfiltered: false,
  },
  [AutomationTargetType.ListAdded]: {
    name: 'List Added',
    target: AutomationTargetType.ListAdded,
    entity: EntityType.CustomerList,
    unfiltered: false,
  },
  [AutomationTargetType.ListRemoved]: {
    name: 'List Removed',
    target: AutomationTargetType.ListRemoved,
    entity: EntityType.CustomerList,
    unfiltered: false,
  },
  [AutomationTargetType.FormSubmitted]: {
    name: 'Form Submitted',
    target: AutomationTargetType.FormSubmitted,
    entity: EntityType.Form,
    unfiltered: false,
  },
  [AutomationTargetType.SubscriberCreated]: {
    name: 'New Subscriber',
    target: AutomationTargetType.SubscriberCreated,
    entity: EntityType.Customer,
    unfiltered: true,
  },
  [AutomationTargetType.MemberCreated]: {
    name: 'New Member',
    target: AutomationTargetType.MemberCreated,
    entity: EntityType.Customer,
    unfiltered: true,
  },
};
