import { trigger, transition, style, animate } from '@angular/animations';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  PLATFORM_ID,
} from '@angular/core';
import {
  BRANDING_CONFIG,
  BrandingConfig,
  IS_PLATFORM_ENABLED,
} from '@sidkik/global';
import { Observable, map, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LayoutFacade } from '../../+state/layout.facade';
import { isPlatformBrowser } from '@angular/common';
import { NotificationService } from '@sidkik/ui';
import { Router } from '@angular/router';
import { HelpPanelService } from '@sidkik/global';
import { AutoDestroy } from '@sidkik/shared';

export enum Section {
  Analytics = 'analytics',
  Create = 'create',
  Interact = 'interact',
  Sell = 'sell',
  People = 'people',
  Settings = 'settings',
  Marketing = 'marketing',
  Community = 'community',
  Availability = 'availability',
  Appearance = 'appearance',
  Integrations = 'integrations',
  Extras = 'extras',
  Help = 'help',
}

@Component({
  selector: 'sidkik-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  animations: [
    trigger('AnimationTrigger0', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease', style({ opacity: 0 })),
      ]),
    ]),
    trigger('AnimationTrigger1', [
      transition(':enter', [
        style({ transform: 'translateX(-100%)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ transform: 'translateX(0)' }),
        animate('300ms ease-in-out', style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
    trigger('AnimationTrigger2', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({ opacity: 1 }),
        animate('300ms ease-in-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class ContainerComponent implements OnInit, OnDestroy {
  condensedlogoUrl = this.brandingConfig.condensedLogo;
  logoUrl = this.brandingConfig.fullLogo;
  isOffCanvasMenu = false;
  now = Date.now();
  breadcrumbTrail$!: Observable<any>;
  debugCounter = 0;
  stripeActive$!: Observable<boolean>;

  activeSection: Section | undefined;

  sections = Section;
  currentPath: string;
  expandMain = false;

  hoverMainTimeout: any;

  isHelpPanelOpen = false;

  @AutoDestroy() private destroy$ = new Subject<void>();

  constructor(
    @Inject(BRANDING_CONFIG) private brandingConfig: BrandingConfig,
    @Inject('APP_VERSION') public version: string,
    private layoutFacade: LayoutFacade,
    @Inject(PLATFORM_ID) private platformId: any,
    private notificationService: NotificationService,
    private router: Router,
    private helpPanelService: HelpPanelService,
    @Inject(IS_PLATFORM_ENABLED) public isPlatformEnabled: boolean
  ) {
    this.currentPath = this.router.url;
    this.setInitialSection(this.currentPath);
  }

  ngOnInit() {
    this.breadcrumbTrail$ = this.layoutFacade.breadcrumbTrail$;
    this.stripeActive$ = this.layoutFacade.stripeAccount$.pipe(
      map((a) => a?.active ?? false)
    );
    this.helpPanelService.isOpen$
      .pipe(takeUntil(this.destroy$))
      .subscribe((isOpen) => (this.isHelpPanelOpen = isOpen));
  }

  toggleOffCanvasMenu() {
    this.isOffCanvasMenu = !this.isOffCanvasMenu;
  }

  toggleSection(section: Section | undefined) {
    // hide it if it's already active
    if (this.activeSection === section) {
      this.activeSection = undefined;
      this.leaveMain();
      return;
    }
    this.activeSection = section;
    this.leaveMain();
  }

  enableDebug() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.debugCounter > 5) {
        localStorage.setItem('ROARR_LOG', 'true');
        this.notificationService.showInfo(
          'Debugging enabled',
          'See console. Must refresh page to take effect.'
        );
      }
      this.debugCounter++;
      return;
    }
  }

  enterMain() {
    this.hoverMainTimeout = setTimeout(() => {
      this.expandMain = true;
    }, 600);
  }

  leaveMain() {
    clearTimeout(this.hoverMainTimeout);
    this.expandMain = false;
  }

  setInitialSection(path: string) {
    let section;
    switch (true) {
      case path.startsWith('/library/emails'):
      case path.startsWith('/library/forms'):
      case path.startsWith('/library/workflows'):
      case path.startsWith('/library/automations'):
      case path.startsWith('/people/campaigns'):
      case path.startsWith('/people/campaign-runs'):
      case path.startsWith('/people/lists'):
      case path.startsWith('/people/tags'):
        section = Section.Marketing;
        break;
      case path.startsWith('/community/spaces'):
      case path.startsWith('/community/comments'):
      case path.startsWith('/config/community'):
        section = Section.Community;
        break;
      case path.startsWith('/scheduling'):
      case path.startsWith('/config/scheduling'):
        section = Section.Availability;
        break;
      case path.startsWith('/config/theme'):
      case path.startsWith('/config/networking'):
      case path.startsWith('/config/navigation'):
        section = Section.Appearance;
        break;
      case path.startsWith('/integrations'):
        section = Section.Integrations;
        break;
      case path.startsWith('/config/addon'):
      case path.startsWith('/config/accounting'):
      case path.startsWith('/config/debug'):
        section = Section.Appearance;
        break;
      case path.startsWith('/analytics') &&
        !path.startsWith('/analytics/dashboard'):
        section = Section.Analytics;
        break;
      // case path.startsWith('/help'):
      //   section = Section.Help;
      //   break;
    }

    this.toggleSection(section);
  }

  closeHelpPanel() {
    this.helpPanelService.close();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
